import React from 'react';
import Head from 'next/head';
import TwoGridResponsiveImage from './TwoGridResponsiveImage';

const PopupVideo = ({ responsiveImage, url }) => {
  const splitUrl = url.split('/');
  const videoId = splitUrl[splitUrl.length - 1];

  return (
    <>
      <Head>
        <script src={url} async></script>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
        ></script>
      </Head>
      <span
        className={`wistia_embed wistia_async_${videoId} popover=true popoverContent=link`}
      >
        <a href="#">
          <TwoGridResponsiveImage responsiveImage={responsiveImage} />
        </a>
      </span>
    </>
  );
};
export default PopupVideo;
