import React from 'react';
import styled from 'styled-components';

import { Markdown } from 'components/Markdown';

const ListRenderer = styled.ul`
  li {
    padding-left: 24px;
  }
`;

const Paragraph = ({ content }) => (
  <Markdown
    customRenderers={{
      ul: ListRenderer
    }}
  >
    {content}
  </Markdown>
);

export default Paragraph;
