import React from 'react';
import styled from 'styled-components';
import { ResponsiveContentfulImage } from 'components/Image';
import { tablets, desktops } from 'styling/breakpoints';

const CenteredResponsiveImage = styled(ResponsiveContentfulImage)`
  max-width: 100%;
  width: 100%;
`;

const ImageWrapper = styled.div`
  height: 461px;
  display: flex;
  align-items: center;

  @media (${tablets}) {
    height: 461px;
  }

  @media (${desktops}) {
    height: 699px;
  }

  position: relative;
  overflow: hidden;
`;

const Image = ({ responsiveImage }) => (
  <ImageWrapper>
    <CenteredResponsiveImage {...responsiveImage} />
  </ImageWrapper>
);

export default Image;
