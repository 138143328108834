import React from 'react';
import styled, { useTheme } from 'styled-components';

import { Video } from '@bscom/components/Video';
import { Spacing } from '@bscom/components/Spacing';
import { Background } from '@bscom/components/Background';

import TwoGridQuote from './TwoGridQuote';
import TwoGridParagraph from './TwoGridParagraph';
import TwoGridResponsiveImage from './TwoGridResponsiveImage';
import TwoGridParagraphQuoteCombo from './TwoGridParagraphQuoteCombo';
import TwoGridPopupVideo from './TwoGridPopupVideo';

const validComponents = {
  twoGridParagraph: TwoGridParagraph,
  twoGridQuote: TwoGridQuote,
  twoGridVideo: Video,
  twoGridParagraphQuoteCombo: TwoGridParagraphQuoteCombo,
  twoGridResponsiveImage: TwoGridResponsiveImage,
  twoGridPopupVideo: TwoGridPopupVideo,
};

const Outline = styled.div`
  ${({
    theme,
    mobileGap,
    tabletGap,
    desktopGap,
    layout,
    centerItems,
    reverseOrder,
  }) => `
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${mobileGap}px;
    margin: 0 auto;

    @media (${theme.breakpoints.tablets}) {
      grid-gap: ${tabletGap}px;
    }

    @media (${theme.breakpoints.desktops}) {
      grid-template-columns: 1fr calc(
          45% - ${desktopGap / 2}px
        );
      grid-gap: ${desktopGap}px;

      ${layout && `grid-template-columns: 1fr 1fr;`}
    }

    ${centerItems && `align-items: center;`}

    ${
      reverseOrder &&
      `
      @media(max-width: ${theme.widths.desktop}px) {
        div:nth-child(1) {
          order: 2;
        }

        div:nth-child(2) {
          order: 1;
        }
      }
    `
    }
  `}
`;

const TwoGrid = ({
  left,
  right,
  layout,
  background,
  centerItems,
  reverseOrder,
  mobileGap = 50,
  tabletGap = 70,
  desktopGap = 60,
  spacing,
  spacingCollection,
}) => {
  const theme = useTheme();

  const LeftComponent =
    validComponents[left.sys.contentType.sys.id] ||
    (() => (
      <div>There was no component found for {left.sys.contentType.sys.id}.</div>
    ));
  const RightComponent =
    validComponents[right.sys.contentType.sys.id] ||
    (() => (
      <div>
        There was no component found for {right.sys.contentType.sys.id}.
      </div>
    ));

  const finalSpacing = spacing || spacingCollection?.items;

  return (
    <Background {...background}>
      <Spacing
        spaces={finalSpacing}
        withXPadding
        contentWidth={theme.widths.maxPage}
      >
        <Outline
          {...{
            layout,
            reverseOrder,
            centerItems,
            mobileGap,
            tabletGap,
            desktopGap,
          }}
        >
          <LeftComponent centerItems={centerItems} {...left} />
          <RightComponent centerItems={centerItems} {...right} />
        </Outline>
      </Spacing>
    </Background>
  );
};

export default TwoGrid;
