import React from 'react';
import styled from 'styled-components';
import { Background } from 'components/Background';
import { Markdown } from 'components/Markdown';
import { tablets } from 'styling/breakpoints';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 50px 20px;

  @media (${tablets}) {
    padding: 60px;
  }
`;

const Headline = styled(Markdown)`
  margin-bottom: 56px;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 42px;
  line-height: 50px;
`;

const LargerText = styled(Text)`
  font-size: 58px;
  line-height: 66px;
`;

const Quote = ({ source, headline, largeText, background }) => {
  return (
    <Background {...background}>
      <Wrapper>
        <Headline customRenderers={{ p: largeText ? LargerText : Text }}>
          {headline}
        </Headline>
        <Markdown>{source}</Markdown>
      </Wrapper>
    </Background>
  );
};

export default Quote;
