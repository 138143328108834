import React from 'react';
import styled from 'styled-components';
import Paragraph from './TwoGridParagraph';
import Quote from './TwoGridQuote';
import { desktops, tablets } from 'styling/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Margin = styled.div`
  margin-bottom: ${({ mobileGap }) => mobileGap}px;

  @media (${tablets}) {
    margin-bottom: ${({ tabletGap }) => tabletGap}px;
  }

  @media (${desktops}) {
    grid-template-columns: 1fr calc(
        45% - ${({ desktopGap }) => desktopGap / 2}px
      );
    margin-bottom: ${({ desktopGap }) => desktopGap}px;
  }
`;

const ParagraphWithQuote = ({
  paragraph,
  quote,
  mobileGap = 50,
  tabletGap = 70,
  desktopGap = 60,
  ...props
}) => (
  <Wrapper>
    <Margin {...{ mobileGap, tabletGap, desktopGap }}>
      <Paragraph {...paragraph} />
    </Margin>
    <Quote {...quote} />
  </Wrapper>
);

export default ParagraphWithQuote;
